export const viewMode = [
  {
    name: "Card Mode",
    icon: "grid",
    mode: "card",
  },
  {
    name: "List Mode",
    icon: "menu",
    mode: "list",
  },

  {
    name: "Cover Mode",
    icon: "cover",
    mode: "cover",
  },
];
